<template>
  <div class="shop-panel">
    <approval  ref="approval" v-if="form && submitVisable"
              :submitVisable="submitVisable"
              :woCodeValue="woCodeValue"
              :saveUrl="saveUrl"
              :businessInfo="form"
              :detailPageName="$route.name"
              :customSubmitBtnName="customSubmitBtnName"
              :processName="processName"
              :taskId="taskId"
              @saveApplyHandle="saveApplyHandle"
              @changeReverseBtn="changeReverseBtn">
    </approval>
    <div :style="{width: flag ? '33.333%' : 'auto',flex: flag ? 'auto' : '1'}">
      <van-button type="info"
                  size="small"
                  @click="saveApplyHandle(true)"
                  class="save">保存</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import approval from '@/components/approval/approval';

export default {
  name: 'addButton',
  props: {
    form: {
      type: [Object],
      default: function () {
        return {};
      }
    },
    validForm: {
      type: Function
    },
    saveUrl: {
      type: String,
      default: ''
    },
    processName: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    submitVisable1: {
      type: Boolean,
      default: false
    },
    taskInstanceId: {
      type: String,
      default: ''
    },
  },
  components: {
    approval
  },

  data () {
    return {
      customSubmitBtnName: ['提交审批'],
      woCodeValue: '',
      newForm: null,
      loading: null,
      submitVisable: false,
      detailPageName: '',
      taskId: '',
      flag: false
    };
  },
  created () {
    if (this.value) this.woCodeValue = this.value;
    else this.woCodeValue = this.saveUrl;

    if (this.taskInstanceId) {
      this.taskId = this.taskInstanceId;
    }

    if (this.submitVisable1) {
      this.submitVisable = this.submitVisable1;
    }

    if (this.$route.query.businessKey) {
      this.submitVisable = true;
    }

    if (this.$route.query.type) {
      this.submitVisable = true;
    }
  },
  watch: {
    value: {
      handler (newName, oldName) {
        if (newName) {
          this.woCodeValue = newName;
        }
      },
      // 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法
      immediate: true
    },
    taskInstanceId: function (curVal, oldVal) {
      this.taskId = curVal;
    },
  },
  methods: {
    changeReverseBtn () {
      this.flag = true;
    },
    saveApplyHandle (showFlag, callback) {
      this.validForm()
        .then(res => {
          if (res) {
            if (callback) {
              callback();
            }
            this.$myHttp({
              url: `/hrtbm/${this.saveUrl}/save`,
              method: 'post',
              data: {
                entity: this.form
              }
            }).then(res => {
              if (res.status == 200) {
                sessionStorage.setItem('formData', JSON.stringify(this.form));
                let data = res.data.entity;
                for (let p in data) {
                  this.form[p] = data[p];
                }
                this.getWorkflow(showFlag);
                this.detailPageName = this.$route.name;
              }
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    async getWorkflow (showFlag) {
      await this.$myHttp({
        method: 'post',
        url: `/hrtbm/${this.saveUrl}/view`,
        data: {
          entity: {
            id: this.form.id
          }
        }
      }).then(res => {
        if (res.status == 200) {
          let data = res.data.entity;
          sessionStorage.setItem('deptAndPost', res.data.ext.deptAndPost);
          for (let p in data) {
            if (!this.form[p]) this.form[p] = data[p];
          }
          if (!this.submitVisable) {
            this.submitVisable = true;
          }
          if (showFlag) {
            Toast.success('保存成功');
          }
        }
      });
    }
  }
};
</script>

<style>
.shop-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
}
.shop-panel .save {
  background: rgba(32, 172, 155, 1);
}
.shop-panel > div {
  flex: 1;
  height: 50px;
}
.shop-panel button {
  color: #fff;
  font-size: 16px;
  height: 50px;
  width: 100%;
  border-radius: 0;
}
</style>

