<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.加班申请')"
                 fixed
                 right-:text="$t('tbm.取消')"
                 @click-left="back"
                 @click-right="back">
    </van-nav-bar>
    <div style="padding-top:46px;margin-bottom:50px;">
      <van-form ref="forms">
        <van-field readonly
                   clickable
                   input-align="right"
                   name="calendar"
                   v-model="form.applyDate"
                   :label="$t('tbm.申请日期')"
                   placeholder="" />
        <van-field readonly
                   clickable
                   v-model="userName"
                   :label="$t('tbm.姓名')"
                   input-align="right"
                   placeholder="" />
        <van-field readonly
                   clickable
                   v-model="deptName"
                   :label="$t('tbm.所在部门')"
                   input-align="right"
                   placeholder="" />
        <van-field v-model="form.name"
                   :label="$t('tbm.加班名称')"
                   required
                   
                   input-align="right"
                   :placeholder="$t('tbm.请输入加班名称')"
                   :rules="[{ required: true, message: this.$t('tbm.请输入加班名称'),trigger: 'change' }]" />
        <van-field v-model="form.reason"
                   style="margin-top:10px;"
                   rows="3"
                   autosize
                   :label="$t('tbm.加班内容')"
                   type="textarea"
                   :placeholder="$t('tbm.请输入加班内容')"
                   required
                   :rules="[{ required: true, message: this.$t('tbm.加班内容不能为空'),trigger: 'change'}]" />
        <van-field v-model="personForm.overtimeDetailList.length"
                   :label="$t('tbm.加班明细')"
                   style="margin-top:10px;"
                   class="overtimeInputClass"
                   readonly
                   required
                   input-align="right"
                   placeholder=""
                   @click-right-icon="toAdd({})"
                   :rules="[{ required: true, message: this.$t('tbm.加班明细不能为空'),trigger: 'change' }]">
          <template #right-icon>
            <img src="@/views/business/images/add.png"
                 alt="">
          </template>
        </van-field>

        <div class="overtimeDetailList">
          <div v-for="(item,index) in personForm.overtimeDetailList"
               :key="index"
               v-show="personForm.overtimeDetailList.length > 0">
            <div v-for="(items,key) in dataList"
                 :key="key">
              <label>{{items.label}}</label>
              <p v-if="items.text == 'dateType'">{{typeconversion(item['dateType'])}}</p>
              <p v-else-if="items.text == 'dateDetail'">{{item[items.text].substring(0, 10)}}</p>
              <p v-else>{{item[items.text]}}</p>
            </div>
            <div class="overtimeButton">
              <p @click="toAdd({detailFormId:item.id,dataRow:item,rowIndex:index.toString()})">{{ $t('tbm.编辑') }}</p>
              <p @click="del(index)">{{ $t('tbm.删除') }}</p>
            </div>
          </div>
          <div style="background:#eee;display:flex;"
               v-show="personForm.overtimeDetailList.length == 0">
            <img src="@/views/business/images/empty.png"
                 alt=""
                 style="width:200px;height:150px;margin:20px auto;">
          </div>
        </div>
      </van-form>
    </div>
    <approvalButton ref="approvalButton"
                    v-if="form"
                    saveUrl="applyOverTime"
                    v-model="procConf.woCode"
                    :taskInstanceId="procConf.taskInstanceId"
                    :processName="procConf.processInstanceName"
                    :form="form"
                    :validForm="validateForm"
                    :submitVisable1="submitVisable1"></approvalButton>
  </div>
</template>
<script>
import approvalButton from '@/views/business/components/approvalButton';
export default {
  components: {
    approvalButton,
  },
  data () {
    return {
      submitVisable1: false,
      form: {
        attachList: [],
        formNo: '',
        applyDate: '',
        reason: '',
        name: '',
        overtimePersonList: [],
        pkOrg: '',
        pkUser: '',
        pkDept: '',
        formStatus: '0',
        flag: '1',
        id: ''
      },
      procConf: {
        businessKey: '',
        processInstanceName: '加班申请',
        woCode: this.TbmCons.BizCode.TbmApplyOvertime,
        taskInstanceId: '',
        variables: {},
        taskType: '',
        mappingId: '',
        status: '',
        activityId: '',
      },
      personForm: {
        overtimeDetailList: [],
        pkOrg: '',
        pkUser: '',
        pkDept: ''
      },
      userName: '',
      deptName: '',
      dataList: [
        {
          label: '加班日期',
          text: 'dateDetail'
        },
        {
          label: '加班类型',
          text: 'dateType'
        },
        {
          label: '开始时间',
          text: 'beginTime'
        },
        {
          label: '结束时间',
          text: 'endTime'
        },
        {
          label: '加班时长',
          text: 'totalHours'
        },
        {
          label: '折合天数',
          text: 'totalDays'
        },
      ],
      flag: false
    }
  },
  methods: {
    back() {
      if(this.$route.query.type) {
        this.$router.go(-1);
      } else {
        this.$router.replace({
          name: 'tbm/overtimeApplication/overtimeApplicationList'
        })
      }
    },
    submitForm (resolve) {
      this.$refs.forms.validate().then(() => {
        if (this.personForm.overtimeDetailList.length === 0) {
          this.$notify({ type: 'danger', message: this.$t('tbm.加班明细不能为空') });
          resolve(false);
        } else {
          let flag = true;
          let dateDetailStrs = '';
          this.personForm.overtimeDetailList.map((item) => {
            dateDetailStrs = dateDetailStrs + item.dateDetail + ',';
          });
          //校验考勤周期是否封存或确认
          this.checkCycleSealedOrConfirm(dateDetailStrs).then(res => {
            if (res.flag) {
              flag = false;
              this.$notify({ type: 'danger', message: res.msg });
            }
          });

          //校验加班日期是否是工作日，工作日不允许申请加班
          this.checkWorkScheduleConfirm(dateDetailStrs).then(res => {
            if (res.flag) {
              flag = false;
              this.$notify({ type: 'danger', message: res.msg });
            }
          });
          setTimeout(() => {
            resolve(flag);
          }, 1000)
        }
      });
    },
    async checkCycleSealedOrConfirm (dateDetails) {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/attendanceRegist/checkCycleSealedOrConfirm?startDate=' + dateDetails,
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    async checkWorkScheduleConfirm (dateDetails) {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/workschedule/checkWorkScheduleConfirm?dateDetails=' + dateDetails,
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    async validateForm () {
      let activitiData = {
        deptAndPost: '',
        customDeptCode: this.form.customDeptCode
      }
      sessionStorage.setItem('activitiData', JSON.stringify(activitiData))

      let deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
      let userName = JSON.parse(localStorage.getItem('userInfo')).cname;
      let officeoutReason=this.form.reason || '';
      if(officeoutReason.length>5){
        officeoutReason=officeoutReason.substring(0.,5)+'……'
      }
      this.procConf.processInstanceName ='加班申请'+'-'+deptName+' '+userName+'-'+officeoutReason+'-'+this.form.totalDays+'天'
      
      return new Promise((resolve, reject) => {
        this.submitForm(resolve);
      })
    },
    del (index) {
      this.$dialog.confirm({
        title: '删除',
        message: this.$t('tbm.确认删除这条信息吗'),
      })
        .then(() => {
          this.personForm.overtimeDetailList.splice(index, 1);
          this.form.overtimePersonList[0].overtimeDetailList = this.personForm.overtimeDetailList;
        })
        .catch(() => {
          // on cancel
        })
    },
    typeconversion: (value) => {
      return value === '0' ? '法定节假日' : value === '1' ? '工作日' : '休息日';
    },
    toAdd (query) {
      this.$router.push({
        name: 'tbm/overtimeApplication/overtimeApplicationDetail',
        query: {
          ...this.$route.query,
          ...query,
        },
        params: {
          ...this.$route.params,
          obj: {},
          form: this.form,
          list: this.personForm.overtimeDetailList
        }
      })
    },
    backFillEditData () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyOverTime/view',
        data: {
          'entity': {
            'id': this.form.id
          }
        }
      }).then(res => {
        let backData = res.data.entity;
        this.form = backData;
        this.getProcConf(backData);
        sessionStorage.setItem('formData', JSON.stringify(this.form));
        this.deptName = backData.deptName;
        this.userName = backData.userName;
        this.procConf.variables.activitiData = {};
        this.procConf.variables.activitiData.deptAndPost = res.data.ext.deptAndPost;
        this.procConf.variables.activitiData.customDeptCode = this.form.customDeptCode;
        this.procConf.businessKey = this.form.id;
      });
    },
    getProcConf (backData) {
      if (backData) {
        if (backData.overtimePersonList.length > 0) {
          let detailList = [];
          for (var i = 0; i < backData.overtimePersonList.length; i++) {
            if (backData.overtimePersonList[i].overtimeDetailList.length > 0) {
              detailList = (backData.overtimePersonList[i].overtimeDetailList);
            }
          }
          this.personForm.overtimeDetailList = detailList;
        }
        if (this.$route.params.obj) {
          let rowIndex = this.$route.params.obj.rowIndex;
          if (rowIndex !== -1) {
            rowIndex = parseInt(rowIndex);
            this.personForm.overtimeDetailList[rowIndex] = this.$route.params.obj.data;
          } else {
            this.personForm.overtimeDetailList.push(this.$route.params.obj.data)
          }
        }
        this.form.overtimePersonList[0].overtimeDetailList = this.personForm.overtimeDetailList;
      }
    }
  },
  created () {
    this.form.id = this.$route.query.businessKey;
    if (this.$route.query['row']) {
      let row = JSON.parse(this.$route.query['row']);
      if (row && row['pid']) {
        this.form.id = this.EhrUtils.isEmpty(this.form.id) ? row['businessKey'] : this.form.id;
        this.procConf.taskInstanceId = row['id'];
        this.procConf.taskType = row['taskType'];
        this.procConf.status = row['status'];
        this.procConf.activityId = row['activityId'];
        this.procConf.activityCode = row['activityCode'];// 节点编码
      }
    }
    this.deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
    this.userName = JSON.parse(localStorage.getItem('userInfo')).cname;
    this.form.pkDept = JSON.parse(localStorage.getItem('userInfo')).deptId;
    this.form.pkOrg = JSON.parse(localStorage.getItem('userInfo')).companyId;
    this.form.pkUser = JSON.parse(localStorage.getItem('userInfo')).id;
    this.personForm.pkDept = this.form.pkDept;
    this.personForm.pkOrg = this.form.pkOrg;
    this.personForm.pkUser = this.form.pkUser;
    this.form.overtimePersonList.push(this.personForm)
    let year = (new Date().getFullYear());
    let month = (new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1);
    let date = new Date().getDate() > 9 ? new Date().getDate() : '0' + new Date().getDate();
    var timestamp = new Date().getTime().toString().substring(6, 12);
    var formNo = 'JB' + year + timestamp;
    this.form.formNo = formNo;
    this.form.applyDate = year + '-' + month + '-' + date;
    if (JSON.parse(sessionStorage.getItem('formData'))) {
      this.submitVisable1 = true;
    }
    let form = this.$route.params.form || JSON.parse(sessionStorage.getItem('formData')) || null;
    sessionStorage.removeItem('formData');
    if (form) {
      this.form = form;
      this.getProcConf(form);
    } else if (this.form.id) {
      this.backFillEditData(true);
    };
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == "approvalPanel") {
      next();
    } else {
      sessionStorage.removeItem('formData');
      next();
    }
  },
}
</script>
<style scoped>
.overtimeDetailList {
  padding: 10px;
}
.overtimeDetailList > div {
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
}
.overtimeDetailList > div > div {
  display: flex;
  margin: 10px 0;
}
.overtimeDetailList > div label {
  margin-right: 20px;
  text-align: right;
  color: rgb(102, 102, 102);
  font-size: 16px;
  width: 70px;
}
.overtimeButton {
  display: flex;
  justify-content: center;
}
.overtimeButton p:nth-child(1) {
  margin-right: 20px;
  background-color: rgb(32, 172, 155);
  color: #fff;
}
.overtimeButton p:nth-child(2) {
  border: 1px solid #f02d2d;
  color: #f02d2d;
}
.overtimeButton p {
  padding: 3px 15px;
  border-radius: 15px;
}
</style>
<style>
.overtimeInputClass input {
  color: #fff !important;
}
.overtimeInputClass .van-field__right-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>

